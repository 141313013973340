import { useState, Fragment } from "react";
import React from "react";
import axios from "axios";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Alert from "@mui/material/Alert";
import validator from "validator";
import ApiMethods from "../../../../ApiManager/ApiMethods";

const ForgotPassword = ({ handleToggle }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    setMessage(null);
    setError(null);

    if (validator.isEmail(email)) {
      // setEmailError("Valid Email :)");
    } else {
      setError("Please insert right email format!");
      setLoading(false);
      return;
    }

    try {
      const data = {
        email: email,
      };
      const response = await ApiMethods.post(
        "auth/client/forgot-password/send-mail",
        data
      );
      setMessage("Password reset link has been sent to your email.");
    } catch (error) {
      setError("Email doesnt exist");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="forgot-password-div">
        <h2 className="h2-pass">Forgot Password</h2>
        <p className="par-pass">
          Enter your email address to receive a password reset link.
        </p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Input
          label="Email"
          placeholder="Enter your email"
          type="email"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <Button
        className={"btn-login-forgot"}
        name={loading ? "Sending..." : "Send Reset Link"}
        onClick={handleForgotPassword}
        disabled={loading || !email}
        type="button"
      />
      {/* <Button
        className="btn-back-to-login"
        name="Back to Login"
        onClick={handleToggle}
        type="button"
      /> */}
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
    </Fragment>
  );
};

export default ForgotPassword;
