import { useState, useEffect, Fragment } from "react";
import React from "react";

const Button = ({ name, onClick, className = "btn-login", handleKeyPress }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      className={`btn tm-btn-submit ${className}`}
    >
      {name}
    </button>
  );
};

export default Button;
