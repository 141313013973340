import "./css/bootstrap.min.css";
import "./css/tooplate-infinite-loop.css";
import Login from "./app/Screens/public/Login";
import Public from "./app/Screens/public/index";
import Secure from "./app/Screens/secure/index";
import { FadeLoader } from "react-spinners";
import React from "react";

import { useState, useEffect, Fragment } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const loginVerification = () => {
    console.log("Login verification called");
    setAuthenticated(true);
  };

  const checkUser = () => {
    console.log("usao opet ovde");
    const token = localStorage.getItem("token");

    // Check if the token exists
    if (token) {
      // Optionally, you can decode and verify the token here
      // For example, using jwt-decode library if you expect a JWT
      setAuthenticated(true);
      setLoading(false);
    } else {
      setAuthenticated(false);
      setLoading(false);
    }
  };

  // Call checkUser on component mount or whenever necessary
  useEffect(() => {
    checkUser(); // Check the user's authentication status when the component mounts
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="spinner-loader">
          <FadeLoader />
        </div>
      ) : (
        <Router>
          <Routes>
            {!authenticated ? (
              <Route
                path="/"
                element={<Public loginVerification={loginVerification} />}
              />
            ) : (
              <Route path="/" element={<Secure checkUser={checkUser} />} />
            )}
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
