import React from "react";

export const EVENTS = [
  {
    event_id: 1,
    // title: "Event 1",
    start: new Date("2024 11 5 09:00"),
    end: new Date("2024 11 5 09:30"),
    // color: "event-red",
  },
  // {
  //   event_id: 2,
  //   title: "Event 2",
  //   start: new Date("2024 11 6 10:00"),
  //   end: new Date("2024 11 6 11:00"),
  //   color: "red",

  // },
];
