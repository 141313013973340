import React from "react";

const Input = ({
  placeholder,
  id,
  nameInput,
  type,
  onChange,
  label,
  error,
}) => {
  return (
    <div className="label-input">
      <label>{label}</label>
      <input
        id={id}
        name={nameInput}
        type={type}
        placeholder={placeholder}
        className="tm-input"
        required
        onChange={onChange}
      />
      <label style={{ color: "red" }}>{error}</label>
    </div>
  );
};

export default Input;
