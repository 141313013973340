import React, { useState, useRef, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import ApiMethods from "../../../ApiManager/ApiMethods";
import { EVENTS } from "./Events/event";
import SidebarComponent from "./SideBar/sidebar";
import { BrowserView, MobileView } from "react-device-detect";
import { isMobile } from "react-device-detect";

const BookingPage = ({ checkUser }) => {
  const [events, setEvents] = useState([]); // New state to hold the events
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [fullAppointment, setFullAppointment] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);

  const [bookNow, setBookNow] = useState([]);
  const [breakAppointment, setBreakAppointment] = useState([]);
  const [requestNow, setRequestNow] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [bookedAppointments, setBookedAppointments] = useState([]);
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [storageCleared, setStorageCleared] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const fullRef = useRef([]);
  const bookedRef = useRef([]);
  const bookNowRef = useRef([]);
  const breakRef = useRef([]);
  const requestNowRef = useRef([]);

  useEffect(() => {
    let firstName = localStorage.getItem("firstName");
    let lastName = localStorage.getItem("lastName");
    setFirstName(firstName);
    setLastName(lastName);

    if (!initialized) {
      const generateWeeklyDates = (hour, minute, dayOffset) => {
        const startOfWeek = new Date();
        const dayOfWeek = startOfWeek.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const offsetToSunday = dayOfWeek; // Days from the current day back to the start of the week (Sunday)
        startOfWeek.setDate(startOfWeek.getDate() - offsetToSunday + dayOffset); // Adjust to the dayOffset from Sunday
        startOfWeek.setHours(hour, minute, 0, 0);
        return startOfWeek;
      };

      // Define times and days for appointments across the week
      const initialFull = [
        generateWeeklyDates(11, 0, 0), // Sunday at 11:00 AM
        generateWeeklyDates(10, 30, 2), // Tuesday at 10:30 AM
        generateWeeklyDates(12, 0, 5), // Friday at 12:00 PM
      ];
      const initialBookNow = [
        generateWeeklyDates(13, 30, 1), // Monday at 1:30 PM
        generateWeeklyDates(14, 0, 3), // Wednesday at 2:00 PM
        generateWeeklyDates(16, 0, 6), // Saturday at 4:00 PM
      ];
      const initialRequestNow = [
        generateWeeklyDates(9, 0, 2), // Tuesday at 9:00 AM
        generateWeeklyDates(15, 30, 4), // Thursday at 3:30 PM
      ];
      const initialBreak = [
        generateWeeklyDates(12, 30, 3), // Wednesday at 12:30 PM
        generateWeeklyDates(13, 30, 5), // Friday at 1:30 PM
      ];

      fullRef.current = initialFull.map((time) => new Date(time));
      bookNowRef.current = initialBookNow.map((time) => new Date(time));
      requestNowRef.current = initialRequestNow.map((time) => new Date(time));
      breakRef.current = initialBreak.map((time) => new Date(time));

      setFullAppointment(fullRef.current);
      setBookNow(bookNowRef.current);
      setRequestNow(requestNowRef.current);
      setBreakAppointment(breakRef.current);
      setInitialized(true);
    }
  }, [initialized]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleConfirm = async (startHour, endHour, close, isBooked) => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const startDate = new Date(startHour);
    const endDate = new Date(endHour);
    console.log(startHour);
    console.log(endHour);

    const startHourReal = `${String(startHour.getHours()).padStart(
      2,
      "0"
    )}:${String(startHour.getMinutes()).padStart(2, "0")}`;
    const endHourReal = `${String(endHour.getHours()).padStart(
      2,
      "0"
    )}:${String(endHour.getMinutes()).padStart(2, "0")}`;

    const data = {
      client_id: id,
      employee_id: 1,
      service_id: 1,
      from: startHourReal,
      to: endHourReal,
      day_id: 1,
      status: "booked",
    };
    console.log(data);

    // const event = [
    //   {
    //     event_id: 1,
    //     title: "Event 1",
    //     start: new Date("2024 11 6 14:00"),
    //     end: new Date("2024 11 6 14:30"),
    //     draggable: true,
    //     // disabled: true,
    //     // color: "event-red",
    //   },
    // ];

    try {
      const startDate = new Date(startHour);

      if (isBooked) {
        // Cancel the booking
        setBookedAppointments((prev) =>
          prev.filter((time) => time.getTime() !== startDate.getTime())
        );
        setBookNow((prev) => [...prev, startDate]); // Move back to book now
      } else {
        // Book the appointment
        setBookedAppointments((prev) => [...prev, startDate]);
        setBookNow((prev) =>
          prev.filter((time) => time.getTime() !== startDate.getTime())
        ); // Remove from book now
      }

      const response = await ApiMethods.post("appointments", data, token);
      close();
    } catch (err) {
      close();
      console.log("Error:", err);
    }
  };

  const handleConfirmPending = async (startHour, endHour, close, isPending) => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");

    const data = {
      client_id: id,
      employee_id: 1,
      service_id: 1,
      from: startHour,
      to: endHour,
      day_id: 1,
      status: "full",
    };

    try {
      const startDate = new Date(startHour);

      if (isPending) {
        // Cancel the booking
        setPendingAppointments((prev) =>
          prev.filter((time) => time.getTime() !== startDate.getTime())
        );
        setRequestNow((prev) => [...prev, startDate]); // Move back to book now
      } else {
        // Book the appointment
        setPendingAppointments((prev) => [...prev, startDate]);
        setRequestNow((prev) =>
          prev.filter((time) => time.getTime() !== startDate.getTime())
        ); // Remove from book now
      }

      // Uncomment the API call when ready
      // const response = await ApiMethods.post("appointments", data, token);
      close();
    } catch (err) {
      close();
      console.log("Error:", err);
    }
  };

  const customEditor = (scheduler) => {
    const { state, close } = scheduler;

    const startHour = `${String(state.start.value.getHours()).padStart(
      2,
      "0"
    )}:${String(state.start.value.getMinutes()).padStart(2, "0")}`;
    const endHour = `${String(state.end.value.getHours()).padStart(
      2,
      "0"
    )}:${String(state.end.value.getMinutes()).padStart(2, "0")}`;

    const date = state.start.value.toLocaleDateString();
    const time = `${startHour} - ${endHour}`;

    const isFull = fullAppointment.some(
      (time) => time.getTime() === state.start.value.getTime()
    );
    const isBookNow = bookNow.some(
      (time) => time.getTime() === state.start.value.getTime()
    );
    const isRequestNow = requestNow.some(
      (time) => time.getTime() === state.start.value.getTime()
    );
    const isBreak = breakAppointment.some(
      (time) => time.getTime() === state.start.value.getTime()
    );
    const isBooked = bookedAppointments.some(
      (time) => time.getTime() === state.start.value.getTime() // Check for booked status
    );

    const isPending = pendingAppointments.some(
      (time) => time.getTime() === state.start.value.getTime() // Check for booked status
    );

    let title = "";
    let button = "";

    if (isBooked) {
      title = "Slot is Booked";
      button = "Cancel Booking";
    } else if (isFull) {
      title = "Slot is Full";
    } else if (isBookNow) {
      title = "Book Now";
      button = "Book Now";
    } else if (isRequestNow) {
      title = "Request Now";
      button = "Request Now";
    } else if (isPending) {
      title = "Cancel booking with request";
      button = "Request cancel";
    }

    return (
      <div className="centered-wrapper">
        <div className="editor-container">
          <div>
            <h2 className="title">{title}</h2>
            <div className="detail-container">
              <p className="detail-text">
                <strong>Service:</strong> {"Trening"}
              </p>
              <p className="detail-text">
                <strong>Price:</strong> 5000 din
              </p>
              <p className="detail-text">
                <strong>Date & Time:</strong> {date} {time}
              </p>
            </div>
            <div className="button-container">
              <button onClick={close} className="button">
                Cancel
              </button>
              {!isFull && ( // Prevent booking on already booked slots
                <button
                  onClick={() =>
                    isBookNow || isBooked
                      ? handleConfirm(
                          state.start.value,
                          state.end.value,
                          close,
                          isBooked
                        )
                      : handleConfirmPending(
                          state.start.value,
                          state.end.value,
                          close,
                          isPending
                        )
                  }
                  className="button"
                >
                  {button}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const cellRender = (props) => {
    const { start, onClick } = props;

    const isFull = fullRef.current.some(
      (time) => time.getTime() === start.getTime()
    );
    const isBookNow = bookNowRef.current.some(
      (time) => time.getTime() === start.getTime()
    );
    const isRequestNow = requestNowRef.current.some(
      (time) => time.getTime() === start.getTime()
    );
    const isBreak = breakRef.current.some(
      (time) => time.getTime() === start.getTime()
    );
    const isBooked = bookedAppointments.some(
      (time) => time.getTime() === start.getTime()
    ); // Check if the slot is booked
    const isPending = pendingAppointments.some(
      (time) => time.getTime() === start.getTime()
    ); // Check if the slot is pending

    const startHour = `${String(start.getHours()).padStart(2, "0")}:${String(
      start.getMinutes()
    ).padStart(2, "0")}`;
    const end = new Date(start.getTime() + 30 * 60000); // Assuming a 30-minute appointment
    const endHour = `${String(end.getHours()).padStart(2, "0")}:${String(
      end.getMinutes()
    ).padStart(2, "0")}`;

    return (
      <div
        onClick={
          isBookNow || isRequestNow || isBooked || isPending ? onClick : null
        }
        className="cell-status"
        style={{
          backgroundColor: isPending
            ? "#FFC72C"
            : isBooked
            ? "" // Yellow for booked
            : isFull
            ? "#BA0021"
            : isBookNow
            ? "#568203"
            : isRequestNow
            ? "#6CB4EE"
            : "#fff",
          fontSize: "10px",
          textTransform: "uppercase",
          fontWeight: "bold",
          borderRadius: "4px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s, transform 0.2s",
          cursor:
            isBookNow || isRequestNow || isBooked || isPending
              ? "pointer"
              : "auto",
          color: "#fff",
          flexDirection: "column", // Ensure elements stack vertically
        }}
        onMouseEnter={
          isBooked || isPending || isRequestNow || isBookNow
            ? (e) => (e.currentTarget.style.transform = "scale(1.02)")
            : null
        }
        onMouseLeave={
          isBooked || isPending || isRequestNow || isBookNow
            ? (e) => (e.currentTarget.style.transform = "scale(1)")
            : null
        }
      >
        {isBooked && (
          <div
            className="event-appointment"
            style={{
              alignItems: "flex-start",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",

              textAlign: "center", // Center the text
            }}
          >
            {/* <p style={{ margin: 0 }}>
              <strong>Service:</strong> Trening
            </p>
            <p style={{ margin: 0 }}>
              <strong>Price:</strong> 500
            </p> */}
            <p style={{ margin: 0 }}>
              <strong>Time:</strong> {startHour} - {endHour}
            </p>
          </div>
        )}
        {/* Displaying booking status only if not booked */}
        {!isBooked && (
          <span>
            {isPending
              ? "Booking....."
              : isFull
              ? "Fully Booked"
              : isBookNow
              ? "Book now"
              : isRequestNow
              ? "Request now"
              : ""}
          </span>
        )}
      </div>
    );
  };

  const logOut = () => {
    localStorage.clear(); //for localStorage
    setStorageCleared((prev) => !prev);
    checkUser();
  };

  // const fetchAppointments = () => {
  //   try {
  //     const response = ApiMethods.get(
  //       "/appointments?from=2024-10-21&to=2024-10-27",
  //       token
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="booking-page-container">
      {isMobile ? (
        <SidebarComponent
          firstName={firstName}
          lastName={lastName}
          logOut={logOut}
        />
      ) : (
        <div className="sidebar">
          <div className="profile-section">
            <h2 className="user-name">
              {firstName} {lastName}
            </h2>
          </div>
          <ul className="menu">
            <li
              className="menu-item"
              onClick={() => console.log("Edit Profile")}
            >
              Edit Profile
            </li>
            <li
              className="menu-item"
              onClick={() => console.log("My Bookings")}
            >
              My Bookings
            </li>
            <li className="menu-item" onClick={logOut}>
              Log out
            </li>
          </ul>
        </div>
      )}

      <div className="booking-page" style={{ zIndex: "0" }}>
        <Scheduler
          view="week"
          week={{
            startHour: 9,
            endHour: 19,
            step: 30,
            weekStartOn: 1,
            weekDays: [0, 1, 2, 3, 4, 5, 6],
            cellRenderer: cellRender,
          }}
          class={"scheduler-container"}
          // events={EVENTS} // Use the events state here
          className="scheduler-container" // Add class for styling
          selectedDate={new Date()}
          customEditor={customEditor}
          disableViewNavigator={true}
          hourFormat="24"
        />
      </div>
    </div>
  );
};

export default BookingPage;
