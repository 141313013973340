import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const SidebarComponent = ({ firstName, lastName, logOut }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Sidebar styles
  const sidebarStyles = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "250px", // Adjust the width of the sidebar
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.5)",
    transform: isSidebarOpen ? "translateX(0)" : "translateX(-100%)", // Sidebar movement
    transition: "transform 0.3s ease-in-out", // Smooth transition effect
  };

  // Content styles
  const contentStyles = {
    padding: "20px",
  };

  // Close button styles
  const closeButtonStyles = {
    display: "flex",
    position: "absolute",
    top: "0",
    right: "0",
    padding: "20px",
    justifyContent: "flex-end",
  };

  // Menu item styles
  const menuItemStyles = {
    padding: "10px",
    cursor: "pointer",
  };

  return (
    <div style={{ zIndex: "1", width: "100%" }}>
      {/* Toggle button to open sidebar */}
      {!isSidebarOpen && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            marginTop: "20px",
          }}
        >
          <div
            onClick={toggleSidebar}
            style={{ position: "absolute", left: "16px" }}
          >
            {/* Left Item */}
            <MenuIcon />
          </div>
          <div style={{ textAlign: "center" }}>
            {/* Center Item */}
            Book appointment
          </div>
        </div>
      )}

      {/* Sidebar */}
      <div style={sidebarStyles}>
        {isSidebarOpen && (
          <div style={contentStyles}>
            <div style={{ padding: "20px" }}>
              <div style={closeButtonStyles} onClick={toggleSidebar}>
                <CloseIcon sx={{ color: "black" }} />
              </div>

              <div className="profile-section">
                <h2 className="user-name">
                  {firstName} {lastName}
                </h2>
              </div>
              <ul className="menu">
                <li
                  style={menuItemStyles}
                  onClick={() => console.log("Edit Profile")}
                >
                  Edit Profile
                </li>
                <li
                  style={menuItemStyles}
                  onClick={() => console.log("My Bookings")}
                >
                  My Bookings
                </li>
                <li style={menuItemStyles} onClick={logOut}>
                  Log out
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarComponent;
