import { useEffect, useState } from "react";
import Register from "./Register";
import Login from "./Login";
import React from "react";

import ForgotPassword from "./ForgotPassword";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

const Public = ({ loginVerification }) => {
  const [view, setView] = useState("login"); // "login", "register", or "forgotPassword"
  const [alert, setAlert] = useState();
  const [verification, setVerification] = useState();

  const handleToggle = (newView) => {
    setView(newView);
  };

  const checkAlert = (validCredentials) => {
    setAlert(validCredentials);
    setTimeout(() => {
      setAlert(undefined);
    }, 800);
  };

  const checkVerification = () => {
    setVerification(true);
  };

  useEffect(() => {}, []);

  return (
    <div>
      <section id="infinite" className="text-white tm-font-big tm-parallax">
        <div className="text-center tm-hero-text-container">
          <div className="alert-succ">
            {alert !== undefined &&
              (alert ? (
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                >
                  You have successfully registered!
                </Alert>
              ) : (
                <Alert variant="filled" severity="error">
                  Invalid credentials
                </Alert>
              ))}
          </div>
          <div className="tm-hero-text-container-inner buttons-fit">
            <div className="uf_login">
              <div className="uf_login_card">
                {view === "register" && (
                  <Register
                    checkAlert={checkAlert}
                    handleToggle={() => handleToggle("login")}
                  />
                )}
                {view === "login" && (
                  <Login
                    loginVerification={loginVerification}
                    checkAlert={checkAlert}
                    handleToggle={() => handleToggle("register")}
                    handleForgotPassword={() => handleToggle("forgotPassword")}
                  />
                )}
                {view === "forgotPassword" && (
                  <ForgotPassword
                    checkAlert={checkAlert}
                    handleToggle={() => handleToggle("login")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Public;
